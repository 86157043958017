.jjjj {
  border-radius: 0 0 20px 20px;
  color: black;
  background-color: white;
  height: 50px;
  padding: 5px 20px;
  transition: 0.5s;
}
.vv {
  border-radius: 0 0 0 0;
  color: white;
  background-color: transparent;
  height: 50px;
  padding: 10px 20px;
}

.bb {
  background-color: red;
  border-radius: 50%;
  color: white;
  position: relative;
  transform: translateY(-50%);
  transition: 0.5s;
  font-size: 16px;
  padding: 8px;
  height: 50px;
  width: 50px;
  border: 5px solid white;
}
